<template>
  <div>
    <b-container fluid class="p-5">
      <b-row>
        <b-col :md="5" class="px-3 border-right">
          <b-tabs content-class="mt-3">
            <b-tab title="消息" active>
              <b-card v-if="flag" class="m-3 p-3" style="min-height: 60vh">
                <p>只显示未读：
                  <el-switch
                      v-model="unreadOnly"
                      active-color="#13ce66"
                      @change="refresh"
                  >
                  </el-switch>
                </p>
                <UnreadCommunication :unread-only="unreadOnly" @add-tab="operateAdd"></UnreadCommunication>
              </b-card>
            </b-tab>
            <b-tab title="选择联系人">
              <b-card class="m-3 p-3">
                <div class="px-3 py-2">
                  <b-form>
                    <small>查找职员</small>
                    <b-row class="px-3">
                      <b-form-input class="w-75" v-model="searchId" placeholder="职员姓名"></b-form-input>
                      <b-button class="w-25" @click="searchStaffs">查找</b-button>
                    </b-row>
                    <b-table :items="staffs" :fields="fields"
                             :per-page="8"
                             :current-page="currentPage"
                             striped responsive="sm">
                      <template #cell(action)="row">
                        <b-button size="sm" variant="outline-secondary" @click="addTab(row.item)">
                          <b-icon icon="arrow-right"></b-icon>
                        </b-button>
                      </template>
                    </b-table>
                    <div style="justify-content: center">
                      <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="5"
                          aria-controls="my-table"
                          @change="searchStaffs"
                      ></b-pagination>
                    </div>
                  </b-form>
                </div>
              </b-card>
            </b-tab>
          </b-tabs>


        </b-col>
        <b-col :md="7">
          <el-tabs v-model="editableTabsValue" type="card" closable @tab-remove="removeTab">
            <el-tab-pane
                v-for="(item, index) in editableTabs"
                :key="item.name"
                :label="item.title"
                :name="item.name"
                :staff="item.staff"
            >
              {{ item.staffId }}
              <CommunicationBox :staff="item.staff"></CommunicationBox>
            </el-tab-pane>
          </el-tabs>
          <div class="mt-5" style="text-align: center" v-show="editableTabs.length===0"><img
              src="https://cos.mooc.huangzhaokun.top/moocImg/undraw_startup_life_re_8ow9.svg"
              alt="" style="width:300px">
            <h4>
              <b-icon icon="arrow-left"></b-icon>
              从左侧列表选择查看
            </h4>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import CommunicationBox from "@/components/Communication/components/CommnicationBox";
import {showStaffsByLikeName} from "@/api";
import UnreadCommunication from "@/components/Communication/components/UnreadCommunication";

export default {
  name: "Communication",
  components: {UnreadCommunication, CommunicationBox},
  data() {
    return {
      flag: true,
      unreadOnly: false,
      rows: 50,
      currentPage: 1,
      searchId: '',
      staffs: [],
      fields: [{key: 'id', label: '工号'}, {key: 'name', label: '姓名'}, {key: 'action', label: '操作'}],
      editableTabsValue: '2',
      editableTabs: [],
      tabIndex: 2
    }
  },
  mounted() {
    this.searchStaffs()
  },
  methods: {
    refresh() {
      this.flag = false
      this.$nextTick(() => {
        this.flag = true
      })
    },
    operateAdd(val) {
      const staff = {
        id: val.originatorId,
        name: val.originatorName
      }
      this.addTab(staff)
    },
    searchStaffs() {
      showStaffsByLikeName(this.searchId, 1, 50).then(res => {
        if (res.data.status) {
          this.staffs = res.data.data.content
        }
      })
    },
    addTab(item) {
      let unexsit = true;
      this.editableTabs.forEach(e => {
        if (e.staff.id + '' === item.id + '') {
          unexsit = false
        }
      })
      if (unexsit) {
        let newTabName = ++this.tabIndex + '';
        this.editableTabs.push({
          title: item.name,
          name: newTabName,
          content: 'New Tab content',
          staff: item
        });
        this.editableTabsValue = newTabName;
      }
    },
    removeTab(targetName) {
      let tabs = this.editableTabs;
      let activeName = this.editableTabsValue;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }
      this.editableTabsValue = activeName;
      this.editableTabs = tabs.filter(tab => tab.name !== targetName);
    }
  }
}
</script>

<style scoped>
.border-right {
  border-right: 1px solid #dee2e6 !important;
}
</style>
